const CustomFieldTypes = {
  TEXT: "text",
  TEXTAREA: "textarea",
  BOOLEAN: "checkbox",
  TAGS: "tags",
  MULTI_INPUT: "multi_input",
  NUMBER: "number",
  IMAGE: "image",
  FILE: "file",
  ARRAY: "array",
  GROUP: "group",
  SELECT: "select",
  DYNAMIC: "dynamic",
  DATE_RANGE: "date_range",
  DATE: "simple_date",
  RATING: "rating",
  EMPTY: "empty"
};

const CustomFieldTypesMapping = {
  [CustomFieldTypes.TEXT]: "TextInput",
  [CustomFieldTypes.TEXTAREA]: "TextAreaInput",
  [CustomFieldTypes.BOOLEAN]: "CheckboxInput",
  [CustomFieldTypes.DATE]: "DateInput",
  [CustomFieldTypes.TAGS]: "TagsInput",
  [CustomFieldTypes.MULTI_INPUT]: "MultiInput",
  [CustomFieldTypes.NUMBER]: "NumberInput",
  [CustomFieldTypes.IMAGE]: "ImageInput",
  [CustomFieldTypes.FILE]: "FileInput",
  [CustomFieldTypes.ARRAY]: "ArrayWrapper",
  [CustomFieldTypes.GROUP]: "InputGroup",
  [CustomFieldTypes.SELECT]: "SelectInput",
  [CustomFieldTypes.DYNAMIC]: "DynamicInput",
  [CustomFieldTypes.DATE_RANGE]: "DateRange",
  [CustomFieldTypes.DATE]: "SimpleDate",
  [CustomFieldTypes.RATING]: "RatingInput",
  [CustomFieldTypes.EMPTY]: "EmptyField" 
};

const SimpleFieldTypes = {
  GROUP: "group",
  LINK: "link",
  SELECT: "SimpleSelect",
  TEXT: "SimpleText",
  NUMBER: "SimpleNumber",
  CHECKBOX: "SimpleCheckbox",
  SWITCH_WORKSPACE: "SwitchWorkspace",
  CHANGE_PASSWORD: "ChangePassword",
  VERIFY_EMAIL: "VerifyEmail",
  USERNAME: "ChangeUsername",
  SLACK_CHANNEL: "SlackDetails"
};

export { CustomFieldTypes, CustomFieldTypesMapping, SimpleFieldTypes };
